import { faEllipsisVertical, faHome, faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useRef, useState } from "react";
import { useOnClickOutside } from "../../hooks";
import { NavigationLink } from "../Primitives/Links";

interface INavigationProps {}

export const Navigation: React.FC<INavigationProps> = () => {
    const ref = useRef<HTMLDivElement>(null);
    const [showMore, setShowMore] = useState(false);
    useOnClickOutside(ref, useCallback(() => setShowMore(false), []));

    return (
        <div className="container fixed bottom-10 flex justify-center items-center">
            <div className="relative">
                {showMore &&
                    <div ref={ref} className="absolute bottom-24 w-full rounded-xl shadow p-4 bg-primary-dark text-secondary flex flex-row space-x-4">
                        <div>
                            <div className="font-bold text-sm py-2">Social</div>
                            <div className="font-thin text-sm">
                                <ul className="space-y-2">
                                    <li><a href="https://twitter.com/PlanBDAO" rel="noreferrer" target="_blank" className="hover:text-secondary-light">Twitter</a></li>
                                    <li><a href="https://t.me/Plan_B_officially" rel="noreferrer" target="_blank" className="hover:text-secondary-light">Telegram</a></li>
                                    <li><a href="https://discord.gg/4XvxugmejR" rel="noreferrer" target="_blank" className="hover:text-secondary-light">Discord</a></li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="font-bold text-sm py-2">Contracts</div>
                            <div className="font-thin text-sm">
                                <ul className="space-y-2">
                                    <li><a href="https://etherscan.io/address/0xe77f82f288a6a7eb4ac295a86fccb1f6d240499b" rel="noreferrer" target="_blank" className="hover:text-secondary-light">Plan B DAO</a></li>
                                    <li><a href="https://etherscan.io/address/0xa5c42a607f22ff4ed0c7271971bc7faa5f53d1a7" rel="noreferrer" target="_blank" className="hover:text-secondary-light">Plan B Mint</a></li>
                                    <li><a href="https://etherscan.io/address/0xdfca5695107386e04a48d69337c01a1cd0b73ffe" rel="noreferrer" target="_blank" className="hover:text-secondary-light">Mint Portal</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                }
                <nav className=" bg-primary-dark py-6 px-6 rounded-xl space-x-2 xl:space-x-6 shadow-xl text-secondary">
                    <NavigationLink to="/">
                        <FontAwesomeIcon icon={faHome} />
                    </NavigationLink>
                    <NavigationLink to="/wallet" className="2xl:hidden">
                        <FontAwesomeIcon icon={faWallet} />
                    </NavigationLink>
                    <NavigationLink to="/mint">Mint</NavigationLink>
                    <NavigationLink to="/stake">Stake</NavigationLink>
                    <NavigationLink to="/dao" className="hidden md:inline-block">DAO</NavigationLink>
                    <button className={`${showMore ? "bg-success text-primary-dark" : "bg-transparent"} w-8 h-8 rounded`} onClick={() => setShowMore(!showMore)}>
                        <FontAwesomeIcon icon={faEllipsisVertical} size="xs" />
                    </button>
                </nav>
            </div>
           
        </div>
    );
}