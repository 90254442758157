interface IDaoProps {}

export const DaoPage: React.FC<IDaoProps> = () => {

    return (
        <div className='font-noto-sans py-10 flex justify-center items-center'>
            <h1>Comming soon...</h1>
            <p></p>
        </div>
    );
}