import { IButtonTypes } from "./Button.interface";

export const bgButton = (type: IButtonTypes) => {
    switch(type) {
        case "basic":
            return "bg-primary-dark";
        case "primary":
            return "bg-primary";
        case "secondary":
            return "bg-secondary";
        case "warning":
            return "bg-warning";
        case "success":
            return "bg-success";
        case "danger":
            return "bg-danger";
    }
}