import { ethers } from "ethers";
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from "wagmi";
import { mintPlanBcontract } from "../utils/config";

export const useCollectReward = () => {
    const {config} = usePrepareContractWrite({
        address: mintPlanBcontract,
        abi: [
            {
                name: "mint",
                type: "function",
                stateMutability: "payable",
                inputs: [],
                outputs: [],
            }
        ],
        functionName: "mint",
        overrides: {
            gasLimit: ethers.utils.parseUnits("500000", "wei"),
        },
        enabled: true
    })

    const { data, write } = useContractWrite(Object.assign(config, { overrides: {
        gasLimit: ethers.utils.parseUnits("500000", "wei"),
    }}) as any);

    const { isLoading, isSuccess, isError } = useWaitForTransaction({hash: data?.hash});

    return {
        data,
        collect: write!,
        isLoading,
        isSuccess,
        isError
    } as const;
}