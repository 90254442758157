
import { useContractRead } from "wagmi";
import { planBContract } from "../utils/config";

export const useTokenContract = (address: string | undefined) => {

    const {data, refetch, status} = useContractRead({
        address: planBContract,
        abi: [
            {
                name: "balanceOf",
                type: "function",
                stateMutability: "view",
                inputs: [{internalType: "address", name: "account", type: "address"}],
                outputs: [{internalType: "uint256", name: "", type: "uint256"}],
            }
        ],
        functionName: "balanceOf",
        watch: true,
        args: [address as any],
        overrides: {
            
        }
    });

    return {
        data,
        refetch
    } as const;
}