import { CoinName } from "../../../interface";
import ethereumImage from "../../../assets/coins/ethereum.png";
import planbImage from "../../../assets/coins/plan-b.png";
import mintPlanBImage from "../../../assets/coins/mint-planb-x64.png";
import denkyInuImage from "../../../assets/coins/dnky.png";

interface CoinIconProps {
    coin: CoinName;
    size?: number;
}

const coinIconMap: {[key in CoinName]: string} = {
    ethereum: ethereumImage,
    planb: planbImage,
    mintPlanB: mintPlanBImage,
    denkyInu: denkyInuImage,
}

export const CoinIcon: React.FC<CoinIconProps> = ({coin, size = 32}) => {

    return (
        <div>
            <img src={coinIconMap[coin]} width={size} height={size} alt={`logo for ${coin}`}/>
        </div>
    );
}