import { INavigationTypes } from "./Navigation.interface";

export const NavigationLinkStyle = (style: INavigationTypes) => {
    switch(style) {
        case "active":
            return "bg-success text-primary-dark hover:bg-success-dark";
        case "inactive":
            return "hover:bg-primary-light text-secondary hover:text-secondary-dark";
        case "disabled":
            return "text-primary";
    }
}