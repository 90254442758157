
import { Chart } from "../components/Charts";
import ClaimTokenModal from "../components/Modals/ClaimTokenModal";
import { CoinIcon } from "../components/Primitives/Coin/CoinIcon";
import { useTokenParams } from "../hooks";

interface ITokenProps {}

export const TokenPage: React.FC<ITokenProps> = () => {
    const {symbol, tokenAddress, desc} = useTokenParams();
    
    return (
        <div className='font-noto-sans flex flex-col'>
            <div className="w-full bg-primary-dark h-64">
                <Chart />
            </div>
            <div className="relative">
                <div className="absolute -top-8 left-8 border-[2px] shadow-xl rounded-full border-secondary-light"><CoinIcon coin={symbol} size={62}/></div>
                <div className="mt-12 px-4">
                    <div>
                        <h2 className="font-bold text-2xl">{desc.name}</h2>
                        <h4 className="text-xl">${desc.symbol} | <a href={`https://etherscan.io/address/${tokenAddress}`} className="text-xs hover:text-secondary-light">{tokenAddress}</a></h4>
                    </div>
                    <div className="text-sm py-2">
                        {desc.description}
                    </div>
                </div>
            </div>
            <div className="main mx-2 py-2 border-t-[1px] border-primary-dark">
                {desc.claim &&                
                    <div className="p-2 flex flex-row">
                        <ClaimTokenModal />
                    </div>
                }
            </div>
        </div>
    );
}