import { ethers } from "ethers";
import { useEffect } from "react";
import { useBalance } from "wagmi";
import { LPOCard } from "../components/Cards";
import { Button } from "../components/Primitives/Button";
import { useAccountContext } from "../context";
import { useTotalSupply } from "../hooks";
import { useCollectReward } from "../hooks/useCollectReward";
import { useMinters } from "../hooks/useMinters";
import { useTokenContract } from "../hooks/useTokenContract";
import { mintContractGoerli, planBContract } from "../utils/config";

interface IMintProps {}

export const MintPage: React.FC<IMintProps> = () => {
    const {address} = useAccountContext();
    const {data} = useTotalSupply(planBContract);
    const minters = useMinters(address);
    const {collect} = useCollectReward();
    const {data: tokenBalance, refetch: tokenRefetch} = useTokenContract(mintContractGoerli);
    const balance = useBalance({
        address: mintContractGoerli,
    })

    // useEffect(() => {
    //     minters.refetch();
    // }, []);

    // console.log(minters.data?.["0"]);
    // console.log(minters.data?.["1"]._hex);
    // if(minters.data?.["1"]._hex) {
    //     console.log("test");
    //     console.log(ethers.utils.formatUnits(minters.data?.["1"]._hex, 0))
    // }
    // console.log(minters.data?.["2"]);
    // console.log(minters.data?.["3"]);

    const timestampToDate = (timestamp: number) => {
        // with minutes and seconds
        return new Date(timestamp * 1000).toLocaleDateString();
    }
    

    const calculateUniswapLaunchPrice = (pool: number, eth: number) => {
        return eth / pool;
    }

    const calculateMarketCap = (pool: number) => {
        if (balance.data && tokenBalance) {
            return (pool * calculateUniswapLaunchPrice(+ethers.utils.formatUnits(tokenBalance?._hex), +balance.data.formatted)).toFixed(2);
        }
        return 0;
    }

    return (
        <div className='font-noto-sans py-10 container flex flex-col-reverse md:flex-row mx-auto'>
            <div className="w-full">
                <LPOCard />
            </div>
            <div className="px-5 w-full sm:w-1/2 max-w-2xl mx-4 pb-10 md:pb-0 hidden">
                <div>
                    <div>
                        <h2 className="font-bold text-2xl">Market Cap</h2>
                        <div>
                            {data && calculateMarketCap(+ethers.utils.formatUnits(data?._hex))} ETH
                        </div>
                    </div>
                </div>
                <div className="flex flex-row space-x-8 my-2">
                    <div className="space-y-2">
                        <h3 className="font-bold text-xl">Total Supply</h3>
                        <div>{data ? ethers.utils.formatUnits(data?._hex) : "loading..."} PLANB</div>
                    </div>
                    <div className="space-y-2">
                        <h3 className="font-bold text-xl">Uniswap Launch Price</h3>
                        <div>{balance.data && tokenBalance && calculateUniswapLaunchPrice(+ethers.utils.formatUnits(tokenBalance?._hex), +balance.data.formatted).toString().slice(0, 8)} ETH</div>
                    </div>
                </div>
                <div>
                    <h2 className="text-xl font-bold">Information</h2>
                    <div>
                        <p>After Mint Session is done, the total supply is set. You can only mint one per wallet and max of 1 ETH. This is a soft protection against against whales wallets and insure that the initial distribution of token is fair.</p>
                    </div>
                </div>
                {minters.data?.["1"]._hex && minters.data?.["1"]._hex !== "0x00" &&
                    <div className="mt-4">
                        <h2 className="text-xl font-bold">Next Reward Cycle</h2>
                        <div>
                            <div>
                                <div>Collect Reward</div>
                                <div>{`${minters.data?.["2"] ? ethers.utils.formatUnits(minters.data?.["2"]._hex) + " PLANB" : "0 PLANB"}`}</div>
                            </div>
                            <div>
                                <div>Next Reward Unlock Time</div>
                                <div>{minters.data?.["1"]._hex && minters.data?.["1"]._hex !== "0x00" ? timestampToDate(+ethers.utils.formatUnits(minters.data?.["1"]._hex, 0)) : ""}</div>
                            </div>
                            <div>
                                <Button onClick={() => collect()}>Collect Rewards</Button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}