import { useEffect } from "react";
import { useBalance } from "wagmi";
import { UniswapWidget } from "../components/Widget";
import { useAccountContext } from "../context";
import { useMintPlanBContract } from "../hooks/useMintPlanBContract";
import { useTokenBalance } from "../hooks/useTokenBalance";
import { useTokenContract } from "../hooks/useTokenContract";
import { denkyInuContract } from "../utils/config";
import { CoinAsset } from "../components/Assets/CoinAsset";
import { ethers } from "ethers";
import { formatBalance } from "../utils/formats";

interface IWalletProps {}

export const WalletPage: React.FC<IWalletProps> = () => {
    const {address} = useAccountContext();
    const {data: tokenBalance, refetch: tokenRefetch} = useTokenContract(address);
    const {data: denkyInuBalance, refetch: denkyInuRefetch} = useTokenBalance(address, denkyInuContract);
    const {data: mtPlanb, refetch: mtPlanbFetch} = useMintPlanBContract(address);
    const balance = useBalance({
        address: address,
    })
  
    useEffect(() => {
      if (address) {
        tokenRefetch();
        mtPlanbFetch();
      }
    }, [address, tokenRefetch]);
    

    return (
        <div className='font-noto-sans container mx-auto my-10'>
            <div className="container space-y-1 px-6">
                <CoinAsset coin="planb" amount={`${tokenBalance ? Number(ethers.utils.formatUnits(tokenBalance?._hex)).toFixed(2) + " PLANB" : "0 PLANB"}`} />
                <CoinAsset coin="mintPlanB" amount={`${mtPlanb ? ethers.utils.formatUnits(mtPlanb?._hex) + " mtPLANB" : "0 mtPLANB"}`} />
                <CoinAsset coin="ethereum" amount={`${formatBalance(balance.data?.formatted ?? "0")} ETH`} />
                <CoinAsset coin="denkyInu" amount={`${denkyInuBalance ? Number(ethers.utils.formatUnits(denkyInuBalance?._hex)).toFixed(2) + " DNKY" : "0 DNKY"}`} />
            </div>
        </div>
    );
}