import axios from "axios";
import { useState, useEffect } from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line } from 'recharts';
import { CoinData } from "../../interface";

interface IChartPtops {}

export const Chart: React.FC<IChartPtops> = () => {
    const [data, setData] = useState<CoinData[]>([]);

    useEffect(() => {
        const today = new Date();
        const data: CoinData[] = [];
        let prevPrice = Math.random() * 10000;
        for (let i = 29; i >= 0; i--) {
            const date = new Date(today.getTime() - i * 24 * 60 * 60 * 1000);
            const price_change = Math.random() * 2000 - 1000;
            const closing_price = Math.max(0, prevPrice + price_change);
            data.push({
              date: date.toISOString().slice(0, 10),
              closing_price: closing_price
            });
            prevPrice = closing_price;
        }
        setData(data);
    }, [])

    return (
        <div className="mt-6">
            <ResponsiveContainer height={200} minHeight={200}>
                <LineChart data={data}>
                    {/* <Tooltip /> */}
                    <Line type="monotone" dataKey="closing_price" stroke="#affc41" fill="#affc41" fillOpacity={0.2} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}