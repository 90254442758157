
import { useContractRead } from "wagmi";
import { WagmiAddressType } from "../context";

export const useTokenBalance = (address: string | undefined, contract: WagmiAddressType) => {

    const {data, refetch} = useContractRead({
        address: contract,
        abi: [
            {
                name: "balanceOf",
                type: "function",
                stateMutability: "view",
                inputs: [{internalType: "address", name: "account", type: "address"}],
                outputs: [{internalType: "uint256", name: "", type: "uint256"}],
            }
        ],
        functionName: "balanceOf",
        watch: true,
        args: [address as any],
        overrides: {
            
        }
    });

    return {
        data,
        refetch
    } as const;
}