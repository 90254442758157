import { ethers } from "ethers";
import { useState } from "react";
import { useAccountContext } from "../../context";
import { useMint } from "../../hooks/useMint";
import { percentage } from "../../utils/math";
import { ProgressLoading } from "../Elements";
import { Button } from "../Primitives/Button";

interface ILPOCardProps {}

export const LPOCard: React.FC<ILPOCardProps> = () => {
    const [eth, setEth] = useState("");
    const {
        connect,
        removeMint,
        isConnected,
        isLPProvider
    } = useAccountContext();
    const {mint} = useMint({eth});
    const onMint = () => {
        (mint as any)?.({
            overrides: {
                // maxFeePerGas: ethers.utils.parseUnits("100", "gwei"),
                value: ethers.utils.parseEther(eth === "" ? "0" : eth),
                gasLimit: ethers.utils.parseUnits("500000", "wei"),
            }
        })
    }

    return (
        <div className=" bg-primary-dark px-5 py-2 shadow-lg mx-4">
            <div className="py-3 border-b-[2px] border-primary">
                <h6 className=" text-secondary font-bold">Public Mint Event</h6>
            </div>
            <div>
                <div className=" bg-success px-4 py-2 font-bold text-primary-dark shadow-sm rounded">
                    <p>Our first mint event is done! Thank you everyone and see you on the next mint event!</p>
                </div>
            </div>
            <div className="w-3/4 mx-auto my-4 space-y-4 text-secondary">
                <div className="flex flex-col space-y-4 text-secondary">
                    <label>Enter your ETH amount</label>
                    <input disabled type={"number"} value={eth} onChange={(e) => setEth(e.target.value)} className="bg-primary px-4 py-2 outline-none rounded-full focus:outline-2 focus:outline-success font-bold" />
                </div>
                <div className=" border-t-[1px] border-primary text-sm py-4 space-y-4">
                    <div>
                        <div className="flex flex-row">Deposit <div className="ml-auto text-right font-bold">{eth === "" ? 0 : eth} ETH</div></div>
                    </div>
                    <div>
                        <div className="border-b-[1px] border-secondary space-y-4 mb-4 pb-4">
                            <div className="flex flex-row">DAO <div className="ml-auto text-right"> {percentage(eth === "" ? 0 : Number(eth) * 100000, 15)} PLB (15%)</div></div>
                            <div className="flex flex-row">Liqudity Pool <div className="ml-auto text-right"> {percentage(eth === "" ? 0 : Number(eth) * 100000, 25)} PLB (25%)</div></div>
                            <div className="flex flex-row">You Recieve<div className="ml-auto text-right"> {percentage(eth === "" ? 0 : Number(eth) * 100000, 60)} PLB (60%)</div></div>
                        </div>
                        <div className="flex flex-row">Mint <div className="ml-auto text-right font-bold">{eth === "" ? 0 : Number(eth) * 100000} PLB</div></div>
                    </div>
                    {ethers.utils.parseEther(eth === "" ? "0" : eth) > ethers.utils.parseUnits("0", "wei") &&
                        <div className="text-success font-bold">
                            <div className="flex flex-row">Unique Founder X2 NFT Trading Card <div className="ml-auto text-right">x1</div></div>
                        </div>
                    }
                </div>
            </div>
            <div className="mb-8 text-right">
                {isConnected ?
                        isLPProvider ? 
                            <Button onClick={() => removeMint()} className="text-white px-4 py-2 font-bold hover:bg-danger-light" $type="danger">Remove your Position</Button> 
                                : 
                            <Button disabled={eth === ""} onClick={onMint} className="text-primary-dark font-normal px-4 py-2 hover:bg-success-light text-sm disabled:bg-primary-light" $type="success">Mint Plan B</Button> 
                    :
                        <Button onClick={() => connect()} $type="primary" className="px-4 py-2 bg-primary font-normal text-secondary text-sm">Connect Wallet</Button>
                }
            </div>
        </div>
    );
}