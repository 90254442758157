import { Link } from "react-router-dom";
import { usePathMath } from "../../hooks/usePathMatch";
import { CoinName } from "../../interface";
import { formatCoinContract, formatCoinName, formatCoinSymbol } from "../../utils/formats";
import { CoinIcon } from "../Primitives/Coin/CoinIcon";

interface ICoinAssetProps {
    coin: CoinName;
    amount: string;
}

export const CoinAsset: React.FC<ICoinAssetProps> = ({coin, amount}) => {
    const isMatchingPath = usePathMath(`/token/${formatCoinContract[coin]}`);
    return (
        <Link to={`/token/${formatCoinContract[coin]}`} className=" block">
            <div className={`flex items-center ${isMatchingPath ? "bg-success text-primary-dark" : "bg-primary-dark hover:bg-secondary-dark hover:text-primary-dark"} cursor-pointer transition-colors py-2 px-4 rounded-md shadow`}>
                <div className="flex-1 flex-row flex items-center">
                    <div>
                        <CoinIcon coin={coin} />
                    </div>
                    <div className="mx-3">
                        <div className="font-bold">{formatCoinSymbol[coin]}</div>
                        <div className="text-sm">{formatCoinName[coin]}</div>
                    </div>
                </div>
                <div className="flex-1 flex justify-end">
                    {amount}
                </div>
            </div>
        </Link>
        
    );
}