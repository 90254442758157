import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAccountContext } from "../../context";
import { formatAddress } from "../../utils/formats";
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { Button } from "../Primitives/Button";

interface IWalletProps {}

export const Wallet: React.FC<IWalletProps> = () => {
    const {address, isConnected, disconnect, connect} = useAccountContext();

    if (address && isConnected) {
        return (
            <div className="bg-primary-dark px-4 py-2 text-secondary flex text-sm justify-center items-center rounded-full h-10">
                {formatAddress(address)}
                <span className="ml-2 text-primary">
                    <Button onClick={() => disconnect()} className="w-7 rounded-full h-7 flex items-center justify-center" $type="secondary">
                        <FontAwesomeIcon size="xs" icon={faRightFromBracket} />
                    </Button>
                </span>
            </div>
        );
        
    }

    return (
        <Button onClick={() => connect()} $type="primary" className="px-4 py-2 bg-primary-dark text-sm text-secondary rounded-full">Connect Wallet</Button>
    );
};