import { useContractRead } from "wagmi"
import { WagmiAddressType } from "../context";
import { planBDAOABI } from "../utils/config";
import { Result } from "ethers/lib/utils.js";

export const useHasAllowance = (erc20Contract: WagmiAddressType, owner: string, spender: string) => {
    const {refetch, status, data} = useContractRead({
        address: erc20Contract,
        abi: planBDAOABI,
        functionName: "allowance",
        watch: true,
        args: [owner, spender],
    });

    return {
        data: (data as Result),
        refetch
    } as const;
}