import { useParams } from "react-router-dom";
import { CoinName, CoinTokenInformation } from "../interface";
import NativeTokens from "../constants//planb-token-list.json";
import { useEffect } from "react";


export const useTokenParams = () => {
    const {id} = useParams<{id: string}>();
    const [symbol, tokenAddress] = id!.split('-') as [CoinName, any];

    return {symbol: symbol as CoinName, tokenAddress, desc: NativeTokens[symbol] as CoinTokenInformation} as const;
}