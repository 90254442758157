import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { configureChains, createClient, goerli, mainnet, WagmiConfig } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import { AccountProvider } from './context';
import { publicProvider } from 'wagmi/providers/public';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Layout } from './shared/Layout';
import { HomePage, MintPage, StakePage, DaoPage } from './pages';
import { WalletPage } from './pages/Wallet';
import { TokenPage } from './pages/Token';

const {chains, provider} = configureChains([mainnet], [publicProvider()]);

const client = createClient({
  autoConnect: true,
  provider: provider,
  connectors: [
    new InjectedConnector({
      chains,
    })
  ]});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <HomePage />
      },
      {
        path: "/mint",
        element: <MintPage />
      },
      {
        path: "/stake",
        element: <StakePage />
      },
      {
        path: "/dao",
        element: <DaoPage />
      },
      {
        path: "/wallet",
        element: <WalletPage />
      },
      {
        path: "/token/:id",
        element: <TokenPage />
      }
    ]
  }
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <WagmiConfig client={client}>
      <AccountProvider>
        <RouterProvider router={router} />
      </AccountProvider>
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
