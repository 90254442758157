import { SwapWidget, Theme } from "@uniswap/widgets";
import '@uniswap/widgets/fonts.css';
import { useState } from "react";
import { Button } from "../Primitives/Button";
import tokenList from "../../constants/token-list.json";

const theme: Theme = {
    primary: "#8ECAE6",
    secondary: "#8AC8E5",
    fontFamily: "Noto Sans",
    container: "#023047",
    interactive: "#02283C",
    module: "#02283C",
    accent: "#034363",
    fontFamilyCode: "#8ECAE6",
    dialog: "#02283C",
    onInteractive: "#8ECAE6",
    onAccent: "#8AC8E5"
}

interface IUniswapWidgetProps {}

export const UniswapWidget: React.FC<IUniswapWidgetProps> = () => {
    const [showWidget, setShowWidget] = useState(true);
    return (
        <div>
            <Button className="w-full py-2 shadow-md hidden" onClick={() => setShowWidget(!showWidget)}>{showWidget ? "close" : "show"} uniswap widget</Button>
            <div className={showWidget ? "flex" : "hidden"}>
                <div className="Uniswap mx-auto flex justify-center mt-4">
                    <SwapWidget tokenList={tokenList.tokens} theme={theme} defaultOutputTokenAddress="0x50ec61A0acdA3213e9e5420e3F0ED4789fFADfde"/>
                </div>
            </div>
        </div>
    );
}