import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { NavigationLinkStyle } from "./NavigationLink.style";

interface INavigationLinkProps {
    to: string
    children: React.ReactNode
    className?: string
}

export const NavigationLink: React.FC<INavigationLinkProps> = ({to, children, className}) => {
    const resolved = useResolvedPath(to);
    const match = useMatch({ path: resolved.pathname, end: true });

    return (
        <Link to={to} className={`p-2 rounded ${NavigationLinkStyle(match ? "active" : "inactive")} ${className}`}>{children}</Link>
    );
}