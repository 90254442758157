
import { useContractRead } from "wagmi";
import { WagmiAddressType } from "../context";

export const useTotalSupply = (erc20: WagmiAddressType) => {

    const {data, refetch, status} = useContractRead({
        address: erc20,
        abi: [
            {
                name: "totalSupply",
                type: "function",
                stateMutability: "view",
                inputs: [],
                outputs: [{internalType: "uint256", name: "", type: "uint256"}],
            }
        ],
        functionName: "totalSupply",
        watch: true,
    });

    return {
        data,
        refetch
    } as const;
}