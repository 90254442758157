import { createContext, useContext, useState } from "react";
import { useAccount, useConnect, useDisconnect, goerli } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import { ConnectArgs, mainnet, SendTransactionResult } from "@wagmi/core";
import * as _tanstack_react_query from '@tanstack/react-query';
import { useMintContract, WriteContractConfig } from "../hooks";

export type WagmiAddressType = `0x${string}` | undefined;

interface IAccountContextProps {
    children: React.ReactNode
}

interface IAccountContextState {
    address: WagmiAddressType
    isConnected: boolean
    isLPProvider: boolean
    data: SendTransactionResult | undefined
    isLoading: boolean
    isSuccess: boolean
    connect: (args?: Partial<ConnectArgs> | undefined) => void;
    disconnect: _tanstack_react_query.UseMutateFunction<void, Error, void, unknown>
    mint: ((overrideConfig?: WriteContractConfig) => void) | undefined
    removeMint: () => void
}

const AccountContext = createContext({} as IAccountContextState)

export const AccountProvider: React.FC<IAccountContextProps> = (props) => {
    const {children} = props;
    const {mint, data, isLoading, isSuccess} = useMintContract();
    const [isLPProvider, setIsLPProvider] = useState(false);
    const {address, isConnected} = useAccount();
    const { connect } = useConnect({
        connector: new InjectedConnector({chains: [mainnet]}),
    })
    const { disconnect } = useDisconnect();

    const removeMint = () => setIsLPProvider(false);

    const value = {
        address,
        isConnected,
        isLPProvider,
        data,
        isLoading,
        isSuccess,
        connect,
        disconnect,
        removeMint,
        mint
    };

    return (
        <AccountContext.Provider value={value}>
            {children}
        </AccountContext.Provider>
    );
}

export const useAccountContext = () => useContext(AccountContext);