import { ethers } from "ethers";
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from "wagmi";

export interface WriteContractConfig {
    overrides?: {
        value?: ethers.BigNumber;
        from?: string;
    }
}

export const useMintContract = () => {
    const {config} = usePrepareContractWrite({
        address: "0xCEC1E3CA3292AABE84DCD21Ad6407AE176997178",
        abi: [
            {
                name: "mint",
                type: "function",
                stateMutability: "payable",
                inputs: [],
                outputs: [],
            }
        ],
        functionName: "mint",
        overrides: {
            // maxFeePerGas: ethers.utils.parseUnits("100", "gwei"),
            gasLimit: ethers.utils.parseUnits("200000", "wei"),
            value: ethers.utils.parseUnits("0.01", "ether"),
        }
        // enabled: false
    })

    const { data, write } = useContractWrite(config);

    const { isLoading, isSuccess } = useWaitForTransaction({hash: data?.hash});

    return { 
        mint: write as ((args?: WriteContractConfig) => void) | undefined,
        data,
        isLoading,
        isSuccess 
    } as const;
}