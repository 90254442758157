import { ethers } from "ethers";
import { useEffect } from "react";
import { useAccountContext } from "../context";
import { useTokenContract } from "../hooks/useTokenContract";
import { Wallet } from '../components/Wallet';
import { Outlet } from "react-router-dom";
import { Navigation } from "../components/Navigation";
import { useBalance } from "wagmi";
import { formatBalance } from "../utils/formats";
import { CoinAsset } from "../components/Assets/CoinAsset";
import { UniswapWidget } from "../components/Widget";
import { useMintPlanBContract } from "../hooks/useMintPlanBContract";
import planBLogo from "../assets/plan-b-logo.png";
import { useTokenBalance } from "../hooks/useTokenBalance";
import { denkyInuContract } from "../utils/config";


interface ILayoutProps {}

export const Layout: React.FC<ILayoutProps> = () => {
    const {address} = useAccountContext();
    const {data: tokenBalance, refetch: tokenRefetch} = useTokenContract(address);
    const {data: denkyInuBalance, refetch: denkyInuRefetch} = useTokenBalance(address, denkyInuContract);
    const {data: mtPlanb, refetch: mtPlanbFetch} = useMintPlanBContract(address);
    const balance = useBalance({
        address: address,
    })
  
    useEffect(() => {
      if (address) {
        tokenRefetch();
        mtPlanbFetch();
      }
    }, [address, tokenRefetch]);

    return (
        <div className='container mx-auto'>
            <header className='p-4 border-b-[2px] border-primary-dark flex items-center min-h-[8vh]'>
                <div>
                <a href="/">
                    <img src={planBLogo} alt="logo" width={120} />
                </a>
                </div>
                <div className='ml-auto flex justify-center items-center space-x-4'>
                <div className='bg-primary-dark px-4 py-2 font-bold text-secondary flex text-sm justify-center items-center rounded-full h-10'>
                    {tokenBalance ? Number(ethers.utils.formatUnits(tokenBalance?._hex)).toFixed(2) + "PLB" : "0 PLB"}
                </div>
                <Wallet />
                </div>
            </header>
            <main className="mb-[20vh] sm:mb-0 flex">
                <div className="w-1/4 hidden 2xl:flex 2xl:basis-1/4 flex-col text-secondary px-2 border-r-[1px] border-r-primary-dark">
                    <div className="py-4">
                        <h4 className="font-bold text-xl">Assets</h4>
                    </div>
                    <div className="space-y-2">
                        <CoinAsset coin="planb" amount={`${tokenBalance ? Number(ethers.utils.formatUnits(tokenBalance?._hex)).toFixed(2) + " PLANB" : "0 PLANB"}`} />
                        <CoinAsset coin="mintPlanB" amount={`${mtPlanb ? ethers.utils.formatUnits(mtPlanb?._hex) + " mtPLANB" : "0 mtPLANB"}`} />
                        <CoinAsset coin="ethereum" amount={`${formatBalance(balance.data?.formatted ?? "0")} ETH`} />
                        <CoinAsset coin="denkyInu" amount={`${denkyInuBalance ? Number(ethers.utils.formatUnits(denkyInuBalance?._hex)).toFixed(2) + " DNKY" : "0 DNKY"}`} />
                    </div>
                </div>
                <div className="w-full 2xl:basis-1/2">
                    <Outlet />
                </div>
                <div className="hidden 2xl:flex 2xl:basis-1/4 border-l-[1px] border-primary-dark px-2">
                    <div>
                        <UniswapWidget />
                    </div>
                </div>
            </main>
            <footer className="h-10 w-full my-10"></footer>
            <Navigation />
        </div>
    );
}