import { CoinName } from '../interface';
import { planBContract, mintPlanBcontract, denkyInuContract } from './config';

export const formatCoinName: {[key in CoinName]: string} = {
    ethereum: "Ethereum",
    planb: "Plan B DAO",
    mintPlanB: "Mint Plan B",
    denkyInu: "Denky Inu"
}

export const formatCoinSymbol: {[key in CoinName]: string} = {
    ethereum: "ETH",
    planb: "PLANB",
    mintPlanB: "mtPLANB",
    denkyInu: "DNKY"
}

export const formatCoinContract: {[key in CoinName]: string} = {
    ethereum: "ethereum-eth",
    planb: `planb-${planBContract}`,
    mintPlanB: `mintPlanB-${mintPlanBcontract}`,
    denkyInu: `denkyInu-${denkyInuContract}`
}


export const formatAddress = (address: string) => 
    `${address.slice(0, 6).toString()}...${address.slice(address.length - 4, address.length)}`

export const formatBalance = (balance: string) => 
    `${parseFloat(balance).toFixed(3)}`