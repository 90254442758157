import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from "wagmi";
import { WagmiAddressType } from "../context";
import { claimDenkyInuABI } from "../utils/config";
import { BigNumber, ethers } from "ethers";

export const useRequestDeposit = (contract: WagmiAddressType, amount: BigNumber) => {
    const {config} = usePrepareContractWrite({
        address: contract,
        abi: claimDenkyInuABI,
        functionName: "request",
        args: [amount],
        overrides: {
            gasLimit: ethers.utils.parseUnits("500000", "wei"),
        },
        enabled: true,
    })

    const { data, write } = useContractWrite(config);
    const { isLoading, isSuccess, isError } = useWaitForTransaction({hash: data?.hash});

    return {
        data,
        request: write,
        isLoading,
        isSuccess,
        isError
    } as const;
}