import { CoinName } from "../../../interface";
import { CoinIcon } from "../Coin/CoinIcon";

interface ITokenInputProps {
    value: string;
    onChange: (value: string) => void;
    coin: CoinName
}

export const TokenInput: React.FC<ITokenInputProps> = ({coin, value, onChange}) => (
    <div className="relative bg-primary w-full flex items-center rounded-md">
        <div>
            <input value={value} onChange={(e) => onChange(e.target.value)} className="bg-primary h-12 pl-2" />
        </div>
        <div className="absolute top-2 right-2 flex items-center"><CoinIcon coin={coin} /> <span className="mx-2 hidden sm:flex">PLANB</span></div>
    </div>
);