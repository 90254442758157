import { ethers } from "ethers";
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from "wagmi";
import { mintContractGoerli } from "../utils/config";

interface IUseMintProps {
    eth: string;
}

export const useMint = ({eth}: IUseMintProps) => {
    const {config} = usePrepareContractWrite({
        address: mintContractGoerli,
        abi: [
            {
                name: "mint",
                type: "function",
                stateMutability: "payable",
                inputs: [],
                outputs: [],
            }
        ],
        functionName: "mint",
        overrides: {
            gasLimit: ethers.utils.parseUnits("500000", "wei"),
        },
        enabled: true
    })

    const { data, write } = useContractWrite(Object.assign(config, { overrides: {
        value: ethers.utils.parseEther(eth === "" ? "0" : eth),
        gasLimit: ethers.utils.parseUnits("500000", "wei"),
    }}) as any);

    const { isLoading, isSuccess, isError } = useWaitForTransaction({hash: data?.hash});

    return {
        data,
        mint: write,
        isLoading,
        isSuccess,
        isError
    } as const;
}