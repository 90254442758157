import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { TokenInput } from '../Primitives/Input'
import { useIncreaseAllowance, useRequestDeposit } from '../../hooks'
import { claimDenkyInuContract, planBContract } from '../../utils/config';
import { ethers } from 'ethers';

export default function ClaimTokenModal() {
    const [amount, setAmount] = useState<string>("0");
    const { data, isLoading, isError, isSuccess, increaseAllowance, hasAllowance } = useIncreaseAllowance(planBContract, claimDenkyInuContract, ethers.BigNumber.from(amount === "" ? "0" : amount));
    const { data: requestData, request} = useRequestDeposit(claimDenkyInuContract, ethers.BigNumber.from(amount === "" ? "0" : amount));

    console.log(requestData, request, hasAllowance);

    let [isOpen, setIsOpen] = useState(true)

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    function onApprove() {
        increaseAllowance!();
    }

    function onDeposit() {
        request!()
    }

    return (
        <>
            <div className="">
                <button
                    type="button"
                    onClick={openModal}
                    className="rounded-md bg-success-dark px-4 py-2 text-sm font-medium text-primary-dark hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                >
                    Claim
                </button>
            </div>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-primary-light p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-secondary-light"
                                    >
                                        Claim your free tokens
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-50">
                                            You need to own at least 10000 of PLANB to claim your free DenkyInu
                                        </p>
                                        <div className='space-y-2 my-2'>
                                            <div>
                                                <TokenInput coin="planb" value={amount} onChange={(value) => setAmount(value)}/>
                                            </div>
                                            <div>
                                                <TokenInput coin="denkyInu" value={(Number(amount) * 10000000).toString()} onChange={(amount) => console.log(amount)}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-4 text-right">
                                        {isSuccess || (hasAllowance !== "0") ?
                                            <button
                                                type="button"
                                                className="inline-flex justify-center rounded-md border border-transparent bg-success px-4 py-2 text-sm font-medium text-primary-dark hover:bg-success-light focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={onDeposit}
                                            >
                                                Deposit
                                            </button>
                                        : 
                                            <button
                                                type="button"
                                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={onApprove}
                                            >
                                                Approve
                                            </button>
                                        }
                                        
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
