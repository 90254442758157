import { Link } from "react-router-dom";

interface IHomePageProps {}

export const HomePage: React.FC<IHomePageProps> = () => {

    return (
        <div className="container mx-auto space-y-8">
            <div className="w-full px-4 xl:px-8 mx-auto hidden">
                <h2 className="font-bold text-5xl text-secondary-dark">Overview</h2>
            </div>
            <div className="flex flex-col px-4 xl:px-8 space-y-4">
                <div className="w-full flex justify-center items-center">
                    <div className="border-[1px] w-full border-primary-light shadow-2xl rounded-lg px-4 py-8 bg-primary-dark space-y-4 xl:min-h-[30vh] flex flex-col">
                        <div className="flex-1">
                            <h4 className="font-thin text-3xl text-center text-secondary">Public <br/> <span className="font-bold">Mint</span></h4>
                        </div>
                        <div className="flex-1">
                            <p className="text-center text-secondary text-sm">
                                Our first public mint event is now done! Thank you for everyone who put their trust in us and supported our project. We are now working on the next mint event.
                            </p>
                        </div>
                        <div className="flex justify-center">
                            {/* <Link to="/mint" className="bg-success hover:bg-success-dark px-4 py-2 rounded text-primary-dark text-sm animate-pulse">Mint is live</Link> */}
                        </div>
                    </div>
                </div>
                <div className="w-full flex justify-center items-center">
                    <div className="border-[1px] w-full border-primary-light shadow-2xl rounded-lg px-4 py-8 bg-primary-dark space-y-4 xl:min-h-[30vh] flex flex-col">
                        <div className="flex-1">
                            <h4 className="font-thin text-3xl text-center text-secondary">Stake <br/> <span className="font-bold">450% APY</span></h4>
                        </div>
                        <div className="flex-1">
                            <p className="text-center text-secondary text-sm">
                                Enjoy the 450% APY on your staked tokens.
                            </p>
                        </div>
                        <div className="flex justify-center">
                            <Link to="/stake" className=" bg-primary px-4 py-2 rounded text-gray-500 text-sm">soon...</Link>
                        </div>
                    </div>
                </div>
                <div className="w-full flex justify-center items-center">
                    <div className="border-[1px] w-full border-primary-light shadow-2xl rounded-lg px-4 py-8 bg-primary-dark space-y-4 xl:min-h-[30vh] flex flex-col">
                        <div className="flex-1">
                            <h4 className="font-thin text-3xl text-center text-secondary">Join <br/> <span className="font-bold">Discord</span></h4>
                        </div>
                        <div className="flex-1">
                            <p className="text-center text-secondary text-sm">
                                Join our discord to get the latest updates, news and be part of the plan b community. <br /> We learn, we grow, we win!
                            </p>
                        </div>
                        <div className="flex justify-center">
                            <a href="https://discord.gg/4XvxugmejR" rel="noreferrer" target="_blank" className="bg-success hover:bg-success-dark px-4 py-2 rounded text-primary-dark text-sm">discord</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}