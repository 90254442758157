import tw from "tailwind-styled-components";
import { IButtonTypes } from "./Button.interface";
import { bgButton } from "./Button.style";

interface IButtonProps {
    $type?: IButtonTypes
}

export const Button = tw.button<IButtonProps>`
    p-3
    uppercase
    rounded-md
    hover:bg-success
    hover:text-primary
    ${({$type = "basic"}) => bgButton($type)}   
`